
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// require('./foundation');

window.Vue = require('vue');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// Vue.component('example-component', require('./components/ExampleComponent.vue'));

const app = new Vue({
    el: '#app',
      data: () => ({
      	bodyOverflowHidden: false,
      	nav: {
      		mobilemenuActive: false,
      		langmenuActive: false,
      	},
	  }),
    methods: {
    	toggleMobileMenu(){
            this.nav.mobilemenuActive = !this.nav.mobilemenuActive;
            if (this.nav.mobilemenuActive == false){
                this.bodyOverflowHidden = false;
            }
            else{
                this.bodyOverflowHidden = true;
            }
        }

    },
     watch: {
    	bodyOverflowHidden(){
    		if (this.bodyOverflowHidden){
				document.body.classList.add("body-overflow-hidden");
			}
			else {
				document.body.classList.remove("body-overflow-hidden");
			}
    	}
    },
    mounted() {


    }
});
